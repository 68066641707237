import { FontSize } from "../../../assets/font";
import Color from "../../../assets/colors";
import styled from "styled-components";
import withTheme from "@mui/styles/withTheme";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CircularProgress from "@mui/material/CircularProgress";
import IconButton from "@mui/material/IconButton";
import CardMedia from "@mui/material/CardMedia";
import CardActionArea from "@mui/material/CardActionArea";
import CheckIcon from "@mui/icons-material/Check";
import BookmarkBorderOutlinedIcon from "@mui/icons-material/BookmarkBorderOutlined";
import BookmarkFilledIcon from "@mui/icons-material/Bookmark";
import Skeleton from "@mui/material/Skeleton";
import Slider from "react-slick";

// Skeleton Components //
export const JobCardSkeleton = withTheme(styled(Skeleton)`
  && {
    min-width: 215px;
    max-width: 215px;
    min-height: 335px;
    max-height: 335px;
    margin: 0 10px;
    border-radius: 20px;

    ${(props) => props.theme.breakpoints.down("sm")} {
      width: 215px;
      height: 280px;
    }
  }
`);

export const TextSkeleton = withTheme(styled(Skeleton)`
  && {
    text-align: center;
    margin-left: 10px;
  }
`);

// Dialog Components //
export const DialogStyled = withTheme(styled(Dialog)`
  && {
    .MuiDialog-container .MuiPaper-root {
      border-radius: 20px;
      min-width: 70%;
      max-width: 80%;
      height: auto;

      ${(props) => props.theme.breakpoints.down("sm")} {
        max-width: 100%;
      }
    }
`);

export const DialogContentStyled = withTheme(styled(DialogContent)`
  && {
    padding: 20px;
    width: 100%;

    ${(props) => props.theme.breakpoints.down("tablet")} {
      min-width: 100%;
      padding: 10px 20px;
    }
  }
`);

// Grid Components //
export const CloseButtonContainer = styled(Grid)`
  && {
    position: relative;
    display: flex;
    justify-content: flex-end;
  }
`;

export const ContentContainer = styled(Grid)`
  && {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    align-self: center;
    transform: translateY(-20px);
  }
`;

export const JobTitleContainer = withTheme(styled(Grid)`
  && {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;

    ${(props) => props.theme.breakpoints.down("tablet")} {
      -webkit-line-clamp: 1;
    }
  }
`);

export const CompanyNameContainer = styled(Grid)`
  && {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }
`;

export const RecommendedJobsContainer = withTheme(styled(Grid)`
  && {
    ${(props) => props.theme.breakpoints.down("tablet")} {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  }
`);

export const CardContentContainer = styled(Grid)`
  && {
    display: flex;
    flex-direction: column;
  }
`;

export const ImageContainer = styled(Grid)`
  && {
    position: relative;
    display: flex;
    flex-direction: row-reverse;
  }
`;

export const ButtonContainer = withTheme(styled(Grid)`
  && {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;

    ${(props) => props.theme.breakpoints.up("tablet")} {
      position: absolute;
      bottom: 55px;
      align-self: center;
    }

    ${(props) => props.theme.breakpoints.down("tablet")} {
      position: relative;
      transform: translateY(10px);
    }

    a,
    a:hover,
    a:active,
    a:visited,
    a:focus {
      text-decoration: none;
    }
  }
`);

export const DescriptionContainer = styled(Grid)`
  && {
    margin: 25px 0;
  }
`;

export const TitleContainer = styled(Grid)`
  display: flex;
  align-items: center;
`;

// Typography Components //
export const HeaderStyled = styled(Typography)`
  && {
    font-size: ${FontSize.desktopHeadline2};
    font-weight: ${FontSize.bold};
    text-align: center;
    margin-left: 10px;
  }
`;

export const HeaderProfile = styled(Typography)`
  && {
    font-weight: ${FontSize.bold};
    text-align: center;
    margin-left: 10px;
  }
`;

export const DescriptionStyled = styled(Typography)`
  && {
    font-size: ${FontSize.desktopBody};
    text-align: center;
  }
`;

export const DescriptionProfile = styled(Typography)`
  && {
    text-align: center;
  }
`;

export const JobTitle = styled(Typography)`
  && {
    font-weight: ${(props) =>
      props.$title ? `${FontSize.bold}` : `${FontSize.regular}`};
    font-size: ${(props) =>
      props.$title ? `${FontSize.desktopBody}` : `${FontSize.desktopCaption}`};
    text-transform: capitalize;
  }
`;

// Card Components //
export const CardStyled = withTheme(styled(Card)`
  && {
    min-width: 215px !important;
    max-width: 215px !important;
    min-height: 335px;
    max-height: 335px;
    margin: 0px 10px;

    ${(props) => props.theme.breakpoints.down("tabletS")} {
      display: block;
      min-height: 280px;
      max-height: 280px;
      margin: 20px 0;
    }

    ${(props) => props.theme.breakpoints.down("sm")} {
      display: block;
      min-width: 100%;
      margin: 1.75rem 0;
    }
  }
`);

export const CardContentStyled = withTheme(styled(CardContent)`
  && {
    padding: 15px;
    display: flex;
    height: 200px;
    flex-direction: column;
  }
`);

export const CardMediaStyled = styled(CardMedia)`
  && {
    z-index: 1;
    position: relative;
  }
`;

export const CardActionAreaStyled = styled(CardActionArea)`
  && {
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
`;

// Div Components //
export const CheckIconContainer = withTheme(styled.div`
  && {
    margin: 10px 0;
    width: 35px;
    height: 35px;
    border-radius: 50px;
    background-color: ${Color.hiredlyPurple};
    display: flex;
    justify-content: center;
    align-items: center;

    ${(props) => props.theme.breakpoints.down("sm")} {
      width: 45px;
      height: 45px;
      margin: 5px 0;
    }
  }
`);

export const SkeletonDiv = withTheme(styled.div`
  && {
    //
  }
`);

export const SkeletonContentFirstRow = withTheme(styled.div`
  && {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 1rem;
  }
`);

export const SkeletonContentSecondRow = withTheme(styled.div`
  && {
    display: flex;
    justify-content: space-evenly;
  }
`);

// Icon Components //
export const CheckIconStyled = withTheme(styled(CheckIcon)`
  && {
    width: 20px;
    height: 20px;
    color: ${Color.white};

    ${(props) => props.theme.breakpoints.down("sm")} {
      width: 25px;
      height: 25px;
    }
  }
`);

export const BookmarkBorderOutlinedIconStyled = styled(
  BookmarkBorderOutlinedIcon
)`
  && {
    position: relative;
    color: ${Color.hiredlyPurple};
  }
`;

export const BookMarkFilledIconStyled = styled(BookmarkFilledIcon)`
  && {
    position: relative;
    color: ${Color.hiredlyPurple};
  }
`;

// Link Components //
export const LinkStyled = styled.a`
  && {
    color: ${Color.hiredlyPurple};
    font-weight: ${FontSize.bold};
  }
`;

// Icon Button Component //
export const IconButtonStyled = styled(IconButton)`
  && {
    position: absolute;
    padding: 0;
    background: ${Color.white};
    opacity: 0.7;
    padding: 6px;
    z-index: 5;
    top: 15%;
    right: 5%;

    & :hover {
      opacity: 1;
      background: ${Color.white};
    }
  }
`;

// Progress Component //
export const CircularProgressStyled = styled(CircularProgress)`
  && {
    &.MuiCircularProgress-root {
      width: 24px !important;
      height: 24px !important;
    }
  }
`;

// Slider Components //
export const SliderStyled = withTheme(styled(Slider)`
  && {
    margin: 25px 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;

    & .slick-list {
      width: 100%;
    }

    & .slick-slide {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      padding: 5px auto;
      width: 100%;
    }

    & .button-slider-next {
      padding: 10px;
      z-index: 1;
      right: 0;
      top: 30%;
      color: ${Color.black};
      visibility: ${(props) => (props.$showNextArrow ? "" : "hidden")};

      & .icon {
        font-size: 2rem;
      }
    }

    & .button-slider-prev {
      padding: 10px;
      z-index: 1;
      left: 0;
      top: 30%;
      color: ${Color.black};
      transform: rotate(180deg);
      visibility: ${(props) => (props.$showPrevArrow ? "" : "hidden")};

      & .icon {
        font-size: 2rem;
      }
    }

    & .button-slider-next:hover,
    .button-slider-prev:hover {
      background-color: rgba(0, 0, 0, 0.05);
    }

    ${(props) => props.theme.breakpoints.down("sm")} {
      width: 100%;
      margin: 0 0 1rem 0;
    }
  }
`);
