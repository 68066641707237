import React from "react";
import {
  IconButtonStyled,
  ArrowForwardIosIconStyled,
  ArrowForwardStyled,
} from "./styles";
import { sendTrackingEvent } from "../../../helpers/tracking_management";

const SliderArrowPrev = ({ to, onClick, id, employerLanding, type }) => {
  const track = () => {
    sendTrackingEvent({
      event: "CE_click-arrow-previous-job-list-our-office",
    });

    onClick();
  };

  return (
    <IconButtonStyled
      aria-label="prev-button"
      className={"button-slider-prev"}
      onClick={type === "prev" ? track : onClick}
      id={id}
    >
      {employerLanding ? (
        <ArrowForwardStyled className="icon" icon={to} onClick={onClick} />
      ) : (
        <ArrowForwardIosIconStyled className="icon" icon={to} />
      )}
    </IconButtonStyled>
  );
};

export default SliderArrowPrev;
