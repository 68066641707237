import { styled as styling } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowForward from "@mui/icons-material/ArrowForward";

// Icon Button Component //
export const IconButtonStyled = styling(IconButton)((props) => ({
  //
}));

// Icon Components //
export const ArrowForwardIosIconStyled = styling(ArrowForwardIosIcon)(
  (props) => ({
    //
  })
);

export const ArrowForwardStyled = styling(ArrowForward)((props) => ({
  //
}));
